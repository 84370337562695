import React, { useState, Suspense } from "react";
import "./App.scss";
import { isMobile } from "react-device-detect";

import Map from "./components/Map/Map";
const Controls = React.lazy(() => import("./components/Controls/Controls"));
const Header = React.lazy(() => import("./components/Header/Header"));
const Overview = React.lazy(() => import("./components/Overview/Overview"));
const Legend = React.lazy(() => import("./components/Legend/Legend"));

function App() {
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const onToggleDetails = (state) => setDetailsVisible(state);
  const handleSidebarToggle = (event) => {
    sidebarVisible ? setSidebarVisible(false) : setSidebarVisible(true);
  };
  return (
    <div className="App w-screen md:h-screen relative overflow-hidden">
      <div className={`flex w-full h-full`}>
        <button
          className={`sidebarToggle absolute top-2 left-0 p-2 z-10`}
          onClick={handleSidebarToggle}
        >
          <img src="back-icon.png" alt="Back" className={`icon`} />
          {sidebarVisible ? "Show map" : "Show filters"}
        </button>
        <div
          className={`${
            sidebarVisible ? "active" : ""
          } sidebar flex flex-col justify-between shadow-lg`}
        >
          <div className={`unswMotif absolute z-0 inset-y-1/8`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={isMobile ? "500" : "338"}
              height="609"
              viewBox="0 0 338 609"
              fill="none"
            >
              <path
                d="M-150.8 415.752L52.4507 609L266.252 490.176L371.533 40.7308L256.137 -90L-150.8 415.752Z"
                fill="#FFDC00"
              />
            </svg>
          </div>
          <Suspense fallback={<div></div>}>
            <Header />
          </Suspense>
          <Suspense fallback={<div></div>}>
            <Controls />
          </Suspense>
          <div className="branding">
            <img src="unsw-hats-logo.png" alt="UNSW HATS logo" />
            <a href="https://insight.domain.com.au/">
              <img
                src="PoweredbyDomainInsight_Logo_RGB-MONO.png"
                alt="UNSW HATS logo"
              />
            </a>
          </div>
        </div>
        {/* <Suspense fallback={<div></div>}> */}
        <Map
          detailsVisible={detailsVisible}
          onToggleDetails={onToggleDetails}
        />
        {/* </Suspense> */}
        <Suspense fallback={<div></div>}>
          <Legend />
        </Suspense>
      </div>
      <div className={`contentSidebar absolute md:top-5 md:right-5`}>
        <Suspense fallback={<div></div>}>
          <Overview
            detailsVisible={detailsVisible}
            onToggleDetails={onToggleDetails}
          />
        </Suspense>
      </div>
    </div>
  );
}

export default App;
