import React, { useContext, useEffect, useMemo, useRef } from "react";
import { DataContext } from "../../contexts/DataContext";
import { FilterContext } from "../../contexts/FilterContext";
import {
  AFFORDABILITY_THRESHOLDS,
  AFFORDABILITY_COLOURS,
  SHORTAGE_COLOURS,
} from "../../config";
import mapboxgl from "mapbox-gl";
import styles from "./Map.module.scss";
import "mapbox-gl/dist/mapbox-gl.css";
import "./Map.module.scss";
import {
  isMobileOnly,
  isTablet,
  useMobileOrientation,
} from "react-device-detect";
import slugify from "slugify";
import { MutatingDots } from "react-loader-spinner";

const Map = ({ detailsVisible, onToggleDetails }) => {
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const { affordabilityData, shortageData, isLoading } =
    useContext(DataContext);
  const { filters, updateFilter } = useContext(FilterContext);
  // const [lastClickedId, setLastClickedId] = useState(null);
  let lastClickedFeatureId = null;
  // const [popup, setPopup] = useState(null);
  const popup = useRef(null);
  const costMode = useRef(null);
  const { isPortrait } = useMobileOrientation();

  const DEFAULT_CENTER = useMemo(() => {
    return [141.3359, -26.0935];
  }, []);

  const DEFAULT_ZOOM = useMemo(() => {
    return 3.5;
  }, []);

  const DEFAULT_BOUNDS = useMemo(() => {
    return new mapboxgl.LngLatBounds(
      // [130, -45.0], // Southwestern corner
      // [165, -10.0] // Northeastern corner

      [92.6827893270061, -49.885780153287854],
      [189.69851845155927, 5.7932970169665055]
    );
  }, []);

  const DEFAULT_PADDING = useMemo(() => {
    return { top: 20, bottom: 20, left: 20, right: 20 };
  }, []);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: process.env.REACT_APP_MAPBOX_STYLE, // style URL
      center: DEFAULT_CENTER, // starting position [lng, lat]
      zoom: DEFAULT_ZOOM, // starting zoom
      minZoom: 3,
      maxZoom: 12,
    });

    // map.setMaxBounds(DEFAULT_BOUNDS);
    // map.fitBounds(DEFAULT_BOUNDS, { padding: DEFAULT_PADDING });

    // Add click event listener
    map.on("click", "affordabilityLayer", (e) => {
      // Reset the last clicked feature's state
      if (lastClickedFeatureId !== null) {
        map.setFeatureState(
          { source: "affordabilityData", id: lastClickedFeatureId },
          { highlighted: false }
        );
      }
      if (e.features.length > 0) {
        if (!detailsVisible) onToggleDetails(true);
        // Check if there is a feature at the click event
        if (e.features.length > 0) {
          const feature = e.features[0];
          // Set the feature state of the clicked feature
          map.setFeatureState(
            {
              source: "affordabilityData",
              id: feature.id,
            },
            { highlighted: true }
          );

          updateFilter("location", {
            label: feature.properties.SA3_NAME_2021,
            value: feature.properties.SA3_CODE_2021,
          });
          // eslint-disable-next-line react-hooks/exhaustive-deps
          lastClickedFeatureId = feature.id;
        }
      }
    });

    // Change the cursor to a pointer when the mouse is over the layer.
    map.on("mouseenter", "affordabilityLayer", () => {
      map.getCanvas().style.cursor = "pointer";
    });

    // Change it back to the default when it leaves.
    map.on("mouseleave", "affordabilityLayer", () => {
      map.getCanvas().style.cursor = "";
    });
    const nav = new mapboxgl.NavigationControl();
    map.addControl(
      nav,
      isMobileOnly || (isTablet && isPortrait) ? "top-right" : "top-left"
    );

    const geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
      showUserLocation: true,
    });
    map.addControl(
      geolocate,
      isMobileOnly || (isTablet && isPortrait) ? "top-right" : "top-left"
    );

    const fullscreen = new mapboxgl.FullscreenControl();
    map.addControl(
      fullscreen,
      isMobileOnly || (isTablet && isPortrait) ? "top-right" : "top-left"
    );

    mapRef.current = map;

    return () => map.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shortageData) {
      const map = mapRef.current;

      if (map.getSource("shortageData")) {
        map.getSource("shortageData").setData(shortageData);
      } else {
        if (shortageData) {
          // debugger;
          map.addSource("shortageData", {
            type: "geojson",
            data: shortageData,
          });
        }
        map.addLayer({
          id: "shortageLayer",
          type: "circle",
          source: "shortageData",
          filter: ["!=", ["get", "shortage"], null],
          paint: {
            // Set circle radius to a fixed size or dynamically based on data
            "circle-radius": 5, // Fixed radius size
            // Color scale for circle based on the 'shortage' property
            "circle-color": [
              "match",
              ["get", "shortageClass"],
              "Much lower",
              SHORTAGE_COLOURS["Much lower"],
              "Lower",
              SHORTAGE_COLOURS["Lower"],
              "Normal",
              SHORTAGE_COLOURS["Normal"],
              "Higher",
              SHORTAGE_COLOURS["Higher"],
              "Much higher",
              SHORTAGE_COLOURS["Much higher"],
              "#cccccc", // Default color if none of the classes match
            ],
            "circle-opacity": 1,
            "circle-stroke-width": 1,
            "circle-stroke-color": "#1a1e2b",
          },
        });
      }
    }
  }, [shortageData]);

  useEffect(() => {
    const map = mapRef.current;

    if (map && affordabilityData && shortageData && filters.cost) {
      costMode.current = filters.cost.value;
      const fillColour = {
        rent: [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          "#f7f7f7",
          [
            "interpolate",
            ["linear"],
            ["get", "affordability"],
            AFFORDABILITY_THRESHOLDS["rent"]["Affordable"],
            AFFORDABILITY_COLOURS["rent"]["Affordable"],
            AFFORDABILITY_THRESHOLDS["rent"]["Acceptable"],
            AFFORDABILITY_COLOURS["rent"]["Acceptable"],
            AFFORDABILITY_THRESHOLDS["rent"]["Moderately unaffordable"],
            AFFORDABILITY_COLOURS["rent"]["Moderately unaffordable"],
            AFFORDABILITY_THRESHOLDS["rent"]["Unaffordable"],
            AFFORDABILITY_COLOURS["rent"]["Unaffordable"],
            AFFORDABILITY_THRESHOLDS["rent"]["Severely unaffordable"],
            AFFORDABILITY_COLOURS["rent"]["Severely unaffordable"],
            AFFORDABILITY_THRESHOLDS["rent"]["Extremely unaffordable"],
            AFFORDABILITY_COLOURS["rent"]["Extremely unaffordable"],
          ],
        ],
        buy: [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          "#f7f7f7",
          [
            "interpolate",
            ["linear"],
            ["get", "affordability"],
            AFFORDABILITY_THRESHOLDS["buy"]["Affordable"],
            AFFORDABILITY_COLOURS["buy"]["Affordable"],
            AFFORDABILITY_THRESHOLDS["buy"]["Moderately unaffordable"],
            AFFORDABILITY_COLOURS["buy"]["Moderately unaffordable"],
            AFFORDABILITY_THRESHOLDS["buy"]["Seriously unaffordable"],
            AFFORDABILITY_COLOURS["buy"]["Seriously unaffordable"],
            AFFORDABILITY_THRESHOLDS["buy"]["Severely unaffordable"],
            AFFORDABILITY_COLOURS["buy"]["Severely unaffordable"],
          ],
        ],
      };

      if (map.getSource("affordabilityData")) {
        map.getSource("affordabilityData").setData(affordabilityData);
        map.setPaintProperty(
          "affordabilityLayer",
          "fill-color",
          fillColour[filters.cost.value]
        );
      } else {
        if (affordabilityData) {
          map.addSource("affordabilityData", {
            type: "geojson",
            data: affordabilityData,
            // generateId: true,
            promoteId: "SA3_CODE_2021",
          });
        }
        map.addLayer({
          id: "affordabilityLayer",
          type: "fill", // or 'line', 'circle', etc., depending on your data
          source: "affordabilityData",
          filter: ["!=", ["get", "affordability"], null],
          paint: {
            "fill-color": fillColour[filters.cost.value],
            "fill-opacity": [
              "case",
              ["boolean", ["feature-state", "highlighted"], false],
              1, // White border for highlighted feature
              0.9, // Normal border color
            ],
            "fill-outline-color": [
              "case",
              ["boolean", ["feature-state", "highlighted"], false],
              "#1A1E2B", // White border for highlighted feature
              "#FFFFFF", // Normal border color
            ],
          },
        });

        // Keep shortageLayer on top
        if (map && map.getLayer("affordabilityLayer")) {
          map.moveLayer("shortageLayer");
        }

        if (!popup.current) {
          popup.current = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
          });
        }

        map.on("mousemove", "affordabilityLayer", function (e) {
          // // Change the cursor style as a UI indicator.

          // // Populate the popup and set its coordinates based on the feature found.
          var coordinates = e.lngLat;
          const thresholds = AFFORDABILITY_THRESHOLDS[costMode.current];
          const affordability = e.features[0].properties.affordability;
          const affordabilityHumanised =
            costMode.current === "rent"
              ? `${affordability.toLocaleString("en", {
                  style: "percent",
                })} of weekly income`
              : `${Math.round(affordability * 100) / 100}x annual income`;
          let affordabilityClass = null;
          for (const [key, threshold] of Object.entries(thresholds)) {
            if (affordability > threshold) {
              affordabilityClass = `${costMode.current}-${key}`;
              // console.log(affordability, ">", threshold, key);
            } else {
              break;
            }
          }

          let shortageClass = null;
          if (shortageData) {
            const f = shortageData.features.find(
              (f) =>
                f.properties.SA3_CODE_2021 ===
                e.features[0].properties.SA3_CODE_2021
            );
            if (f.properties.shortageClass)
              shortageClass = f.properties.shortageClass;
          }
          const shortageHumanised = e.features[0].properties.shortage
            ? `${Math.round(e.features[0].properties.shortage * 100) / 100}`
            : "";

          const description = `<div class="${styles.tooltip}">
              <h4 class="text-lg">${e.features[0].properties.SA3_NAME_2021}</h4>
              <h5>Affordability to ${costMode.current}</h5>
              <span class="${styles["marker"]} ${
            affordabilityClass
              ? styles[slugify(affordabilityClass, { lower: true })]
              : ""
          }">${
            affordabilityClass.split("-")[1]
          } (${affordabilityHumanised})</span>
          <h5>Workforce shortage</h5>
          <span class="${styles["marker"]} ${
            shortageClass ? styles[slugify(shortageClass, { lower: true })] : ""
          }">
          ${shortageClass} (demand:supply ratio = ${shortageHumanised})
          </span>
            </div>`;

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          // Remove any existing popup
          // if (popup.current) {
          //   popup.current.remove();
          //   // popup = null;
          // }
          if (popup.current && typeof popup.current.setLngLat === "function") {
            popup.current
              .setLngLat(coordinates)
              .setHTML(description)
              .addTo(map);
          }
        });

        map.on("mouseleave", "affordabilityLayer", function () {
          if (popup.current) {
            popup.current.remove(); // This will remove the popup from the map
          }
        });
      }
    }
  }, [affordabilityData, shortageData, filters]);

  useEffect(() => {
    const map = mapRef.current;
    // console.log("ttt selectedLocation", selectedLocation);
    if (
      map &&
      map.getLayer("affordabilityLayer") &&
      map.getSource("affordabilityData") &&
      filters.location
    ) {
      const sourceId = "affordabilityData"; // The ID of your source, adjust as necessary

      // Ensure the source is loaded
      if (!map.getSource(sourceId)) {
        console.warn("Source not yet loaded");
        return;
      }

      // Query for the feature using the feature ID
      let features = map.querySourceFeatures(sourceId, {
        sourceLayer: "affordabilityLayer", // Adjust as needed if using vector tiles
        filter: ["==", ["get", "SA3_CODE_2021"], filters.location.value], // Adjust the property name if necessary
      });

      // if feature out of viewport, query the data
      if (features.length === 0) {
        features = affordabilityData.features.filter((f) => {
          return f.properties.SA3_CODE_2021 === filters.location.value;
        });
      }

      if (features.length > 0) {
        const feature = features[0];

        // Assuming the feature is a polygon or linestring
        const coordinates = feature.geometry.coordinates;
        let bounds = new mapboxgl.LngLatBounds();

        // Calculate bounds based on geometry type
        switch (feature.geometry.type) {
          case "Point":
            bounds.extend(coordinates);
            break;
          case "LineString":
          case "Polygon":
            coordinates.forEach((coord) => {
              coord.forEach((point) => {
                // Temporarily store the bounds to calculate center and extend a bit further
                bounds.extend(point);
              });
            });

            // Calculate "padding" in degrees - this is a very crude form of padding
            var latAdjustment =
              (bounds.getNorthEast().lat - bounds.getSouthWest().lat) * 2;
            var lngAdjustment =
              (bounds.getNorthEast().lng - bounds.getSouthWest().lng) * 2;

            var sw = new mapboxgl.LngLat(
              bounds.getSouthWest().lng - lngAdjustment,
              bounds.getSouthWest().lat - latAdjustment
            );
            var ne = new mapboxgl.LngLat(
              bounds.getNorthEast().lng + lngAdjustment,
              bounds.getNorthEast().lat + latAdjustment
            );

            // Adjusted bounds
            bounds = new mapboxgl.LngLatBounds(sw, ne);
            break;
          case "MultiPolygon":
          case "MultiLineString":
            coordinates.forEach((polygon) => {
              polygon.forEach((ring) => {
                ring.forEach((point) => {
                  bounds.extend(point);
                });
              });
            });
            var multi_latAdjustment =
              (bounds.getNorthEast().lat - bounds.getSouthWest().lat) * 2;
            var multi_lngAdjustment =
              (bounds.getNorthEast().lng - bounds.getSouthWest().lng) * 2;

            var multi_sw = new mapboxgl.LngLat(
              bounds.getSouthWest().lng - multi_lngAdjustment,
              bounds.getSouthWest().lat - multi_latAdjustment
            );
            var multi_ne = new mapboxgl.LngLat(
              bounds.getNorthEast().lng + multi_lngAdjustment,
              bounds.getNorthEast().lat + multi_latAdjustment
            );

            // Adjusted bounds for better fit
            bounds = new mapboxgl.LngLatBounds(multi_sw, multi_ne);
            break;
          default:
            console.log("Unsupported feature type for bounds calculation");
            return;
        }

        map.fitBounds(bounds, {
          padding: DEFAULT_PADDING, // or any other padding value
          // maxZoom: 8, // or any other zoom level
        });
        map.setFeatureState(
          {
            source: "affordabilityData",
            id: filters.location.value,
          },
          { highlighted: true }
        );
      } else {
        map.flyTo({
          center: DEFAULT_CENTER, // Longitude, Latitude
          zoom: DEFAULT_ZOOM, // Target zoom level
          essential: true, // This ensures the animation occurs even if it's initiated by a user gesture
        });
      }

      if (filters.location.value === null) {
        map.flyTo({
          center: DEFAULT_CENTER, // Longitude, Latitude
          zoom: DEFAULT_ZOOM, // Target zoom level
          essential: true, // This ensures the animation occurs even if it's initiated by a user gesture
        });
      }
    }
  }, [
    filters.location,
    affordabilityData,
    DEFAULT_BOUNDS,
    DEFAULT_PADDING,
    DEFAULT_CENTER,
    DEFAULT_ZOOM,
  ]);

  return (
    <>
      {isLoading && (
        <div className={styles.loadingOverlay}>
          <MutatingDots
            height="100"
            width="100"
            color="#FFDC00"
            secondaryColor="#FFDC00"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}

      <div
        className={`${styles.Map} grow w-full h-full`}
        ref={mapContainerRef}
      />
    </>
  );
};

export default Map;
